import styled from 'styled-components';

export const Container = styled.button`
  text-align: center;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: var(--main-color);
  color: var(--main-bg);
  font-weight: 500;
  font-size: 18px;
`;
