import styled from 'styled-components';

export const PageHolder = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-top: 50px;

    color: var(--main-bg);
    font-size: 32px;
    text-align: center;
  }
`;

export const ContentHolder = styled.div`
  width: 60%;
  min-height: 600px;

  margin-top: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);

  border: 2px solid var(--main-color);

  @media (max-width: 768px) {
    & {
      flex-direction: column;
      width: 90%;
    }
  }
`;

export const SignUpHolder = styled.div`
  width: 50%;
  height: 100%;
  min-height: 600px;

  border-radius: 50px 0 0 50px;

  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--main-bg);

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      padding: 50px;
      border-radius: 50%;
      background-color: var(--main-color);
      svg {
        color: #fff;
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
      }
      &:hover {
        div {
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
        }
      }
    }

    h2 {
      margin-top: 20px;
      color: var(--main-color);
      font-size: 28px;
    }
  }

  @media (max-width: 768px) {
    & {
      width: 100%;
      border-radius: 50px 50px 0 0;
      min-height: 450px;
    }
  }
`;

export const SignInHolder = styled.div`
  width: 50%;
  height: 100%;
  min-height: 600px;

  border-radius: 0 50px 50px 0;

  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h2 {
    color: var(--main-bg);
    font-weight: 500;
    font-size: 32px;
    margin: 50px 0 30px 50px;
  }

  @media (max-width: 768px) {
    & {
      align-items: center;
      width: 100%;
      height: 50%;
      border-radius: 0 0 50px 50px;
      h2 {
        margin: 0px;
      }
    }
  }
`;

export const FormHolder = styled.div`
  width: 80%;
  height: 100%;

  margin: 0 0 50px 50px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
    label {
      font-size: 18px;
    }
    .inputHolder {
      margin: 5px 0 15px;
      border-radius: 10px;
    }

    button[type='submit'] {
      margin-top: 10px;

      height: 100%;
      border: none;
      background-color: var(--main-color);
      color: #000;
      font-weight: 600;
      font-size: 18px;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);

      padding: 10px 50px;
      border-radius: 30px;

      text-align: center;
    }
  }

  @media (max-width: 768px) {
    & {
      width: 90%;
      height: 50%;
      margin: 30px 0 0;
    }
  }
`;
