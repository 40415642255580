import React, { useCallback, useRef, useState } from 'react';
import { PageProps, navigate } from 'gatsby';
import Layout from 'components/_layout';
import queryString from 'query-string';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import Input from 'components/Input';
import { FaUserPlus } from 'react-icons/fa';
import Button from 'components/Button';
import { FiLock, FiUser } from 'react-icons/fi';
import api from 'services/api';

import { createCart, ICartDTO } from 'components/Cart';
import {
  PageHolder,
  ContentHolder,
  SignUpHolder,
  SignInHolder,
  FormHolder,
} from '../styles/pages/minha-conta';

interface FormData {
  email: string;
  password: string;
}

const Contact = (props: PageProps) => {
  const { location } = props;

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const queryParams = queryString.parse(location.search);

  const { redirect } = queryParams;

  const handleSubmit = useCallback(async (data: FormData, { reset }) => {
    try {
      setLoading(true);

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Insira um e-mail válido')
          .required('E-mail obrigatório'),
        password: Yup.string().required('Senha obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      api
        .post('/sessions', data)
        .then(res => {
          const { token, user } = res.data;

          localStorage.setItem('@FGLivraria:token', token);
          localStorage.setItem('@FGLivraria:user', JSON.stringify(user));

          const cart = localStorage.getItem('@FGLivraria:cart');
          if (cart) {
            const cartParsed: ICartDTO = JSON.parse(cart);
            cartParsed.userId = user.id;
            localStorage.setItem(
              '@FGLivraria:cart',
              JSON.stringify(cartParsed)
            );
          } else {
            createCart();
          }

          api.defaults.headers.authorization = `Bearer ${token}`;

          toast.success(
            '✔️ Login efetuado com sucesso. Redirecionando para outra página...',
            {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          reset();
          setTimeout(() => {
            if (redirect === '321') {
              navigate('/finalizar-compra');
            } else if (
              redirect === '123' &&
              localStorage.getItem('@FGLivraria:cart')
            ) {
              navigate('/carrinho');
            } else {
              navigate('/');
            }
          }, 3000);
        })
        .catch(err => {
          toast.error('❌ Erro ao fazer login. Tente novamente.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.error(`Erro: ${err}`);
        });
    } catch (err) {
      console.error(`Erro: ${err}`);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        let error = '❌ Erro ao fazer login. Tente novamente.';

        if (errors.email) {
          error = `❌ ${errors.email}`;
        } else if (errors.password) {
          error = `❌ ${errors.password}`;
        }

        toast.error(error, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return;
      }

      toast.error('❌ Erro ao fazer login. Tente novamente.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Layout pathname={location.pathname}>
      <PageHolder>
        <h1>Já possuí uma conta? Faça login. Se não, registre-se!</h1>
        <ContentHolder>
          <SignUpHolder>
            <a
              href={
                redirect === '321'
                  ? '/nova-conta?redirect=321'
                  : redirect === '123'
                  ? '/nova-conta?redirect=123'
                  : '/nova-conta'
              }
            >
              <div>
                <FaUserPlus size={200} />
              </div>
              <h2>Registre-se</h2>
            </a>
          </SignUpHolder>
          <SignInHolder>
            <h2>Faça login</h2>
            <FormHolder>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <label htmlFor="email">E-mail</label>
                <Input
                  icon={FiUser}
                  iconSize={25}
                  id="email"
                  name="email"
                  placeholder="Digite seu e-mail"
                />
                <label htmlFor="password">Senha</label>
                <Input
                  icon={FiLock}
                  iconSize={25}
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Digite sua senha"
                />
                <Button loading={loading} type="submit">
                  ENTRAR
                </Button>
              </Form>
            </FormHolder>
          </SignInHolder>
        </ContentHolder>
      </PageHolder>
    </Layout>
  );
};

export default Contact;
